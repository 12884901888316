<template lang="html">
  <div class="main-page">
    <Navlid :list="navlist"></Navlid>
    <div class="ifr-main" v-if="contract_approve_img">
      <iframe :src="contract_approve_img" style="width: 100%; height: 100%" frameborder="0"></iframe>
    </div>
    <div class="btns">
      <MyButton right @click="dialogVisible = true">拒绝</MyButton>
      <MyButton @click="agree" :disabled="iscomplete">同意</MyButton>
    </div>
    <el-dialog title="拒绝" :visible.sync="dialogVisible" width="30%" @close="handleClose" :show-close="false"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="refuseRules" ref="refuseRuleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="拒绝原因" prop="contract_desc">
          <el-input v-model="ruleForm.contract_desc" class="public-input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton @click="dialogVisible = false" right>取消</MyButton>
        <MyButton @click="refuse" :disabled="iscomplete">确定</MyButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";

export default {
  name: "approval",
  data () {
    return {
      navlist: [
        {
          title: "合同列表",
          url: "/contract/contractlist",
        },
        {
          title: "审批",
        },
      ],
      id: "",
      contract_content: "",
      dialogVisible: false,
      ruleForm: {
        contract_desc: "",
      },
      refuseRules: {
        contract_desc: [
          { required: true, message: "请输入拒绝原因", trigger: "blur" },
        ],
      },
      iscomplete: false,
      contract_is_agree: "",
      contract_approve_img: "",
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.contract_is_agree = this.$route.query.status;
  },
  mounted () {
    this.getDetail();
  },
  methods: {
    handleClose () {
      this.ruleForm = {
        contract_desc: "",
      };
    },
    agree () {
      this.iscomplete = true;
      this.$request
        .HttpPost("/contract/isAgreeSave", {
          contract_id: this.id,
          contract_is_agree: this.contract_is_agree,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.$router.push({
            path: "/contract/contractlist",
          });
        })
        .finally(() => {
          this.iscomplete = false;
        });
    },
    refuse () {
      this.iscomplete = true;
      this.$refs.refuseRuleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/contract/isAgreeSave", {
              contract_id: this.id,
              contract_desc: this.ruleForm.contract_desc,
              contract_is_agree: 20,
            })
            .then((res) => {
              this.dialogVisible = false;
              this.$common.notifySuccess(res.msg);
              this.$router.push({
                path: "/contract/contractlist",
              });
            })
            .finally(() => {
              this.iscomplete = false;
            });
        } else {
          this.iscomplete = false;
        }
      });
    },
    getDetail () {
      this.$request.HttpGet(`/contract/edit?id=${this.id}`).then((res) => {
        if (res.data) {
          this.contract_approve_img = process.env.VUE_APP_LOGIN + "/" + res.data.contract_tpl;
        }
      });
    },
    draw (base64) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      const base64aa =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAvlJREFUSEvdlj1oVEEQx/+zd20goigkolFMVBRFUkiIlUgSGxs5xHxozL23L6eI2FhZBFKKECTF7WxQo1ExpLFSI6k0oiKi4Aci2CkRsRDtPN/IHnfh5eXjPrwq2719u/PbmfnP7BJqPLTWmwBYAMPM/CRunmrJ8zxvi1LqDYC6gt1OZp6OMvJAz/MOKqV6iahBRGS5QxARicgsMw/H1/i+v4uI3rp5EUkT0YgDE1GHMeZRcT1prTsAPKzA02lm7oyu11rvAPChAGu31j4dGBhoSCaTswCaRKTLWptnOOB7ADtFpL2xsfFZKfDQ0JCLwHwUYp7ttta+i9hw9kP3zcz5aLqJHIAvzLy5FCz+P51Ob0skEp8K863M/Cq6xvf9YSK6COA2M/cUgb8AzDFzcyXAgkA+540QHTLGzMRg40R0AsAEM/dFc/gTwA9m3loucHBwsCkMQwdzIsrnLJbTGwD6iGjSGHNsgUq11hUBPc9rVUq9LAhkn7X2ddRgEATXRKQfwCgzn11Uh5UAgyDYKyJFwKKcaa2nABxdDlbMYVkeep63QSk1F5V+LIy3AHQvFcaKQxoEQbOIPAewJgzDtrGxsQXlo7UeBXAGwE1mdkJZdriyWNFDrfU6AE4gdUqpA9ls1hXz/NBau77pAbjKzOlSwisJ9DxvT6E/jjDz+RhsEkCKiK4bY04V/2Uyme25XK5XKTVljHG9dX6UBEba1gVmvlTcqbXO50xEstbaTKwGe4hoAsA5Zr5SETDSuiZE5LLbrJQ6LSI+EVljjI6H0ff9I0R0j4hOGmNcTZbvoe/7LUT0MW5URO5Ya7uXytl/AVOpVKK+vt7laT0RJUXE3VLf4yePhbR6D0upruYergrgbwBfmbmlGm+W2hMEQZeI3AfQz8zjcZX+AfCNmTfWClh4I80QUWCM4TjQ9cX9IuIeUY/DMAyVUqoauFLqby6XW6uUygJoI6LDxpgHC4CF++2Fq+dqICvsucvMx6PvH7e2pu/Scg68+oH/AFB/kCwFIvqiAAAAAElFTkSuQmCC";
      img.src =
        "http://192.168.1.247:8010/property/public/pdf/pdf-202304241054001455321155.pdf";
      img.onload = () => {
        console.log("this.$refs.canvasRef", this.$refs);

        const context = this.$refs.canvasRef.getContext("2d");

        context.drawImage(img, 0, 0);
        const base64Image = canvas.toDataURL();
        const img2 = new Image();
        img2.onload = function () {
          URL.revokeObjectURL(img2.src); // 释放内存
        };
        img2.src = base64Image;
        document.body.appendChild(img2);
      };
    },

    drawCross () {
      // 加载PDF.js库
      const script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.8.335/pdf.min.js";
      script.onload = function () {
        // 加载PDF文件
        const xhr = new XMLHttpRequest();
        xhr.open(
          "GET",
          "http://localhost:3333/app/front/pdf-202304241054001455321155.pdf",
          true
        );
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
          if (xhr.status === 200) {
            // 将PDF文件转换为Uint8Array对象
            const data = new Uint8Array(xhr.response);
            // 加载PDF文件
            pdfjsLib.getDocument(data).promise.then(function (pdf) {
              // 加载第一页
              pdf.getPage(1).then(function (page) {
                const scale = 1.5;
                const viewport = page.getViewport({ scale: scale });
                const canvas = document.createElement("canvas");
                document.body.appendChild(canvas); // 添加到页面中
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                const context = canvas.getContext("2d");
                // 渲染页面
                page
                  .render({
                    canvasContext: context,
                    viewport: viewport,
                  })
                  .promise.then(function () {
                    // 释放内存
                    page.cleanup();
                    pdf.cleanup();
                  });
              });
            });
          }
        };
        xhr.send();
      };
      document.head.appendChild(script);
    },

    getXmr () {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.contract_approve_img, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          const img = document.createElement("img");
          img.onload = function () {
            URL.revokeObjectURL(img.src); // 释放内存
          };
          img.src = URL.createObjectURL(xhr.response);
          document.body.appendChild(img);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ifr-main {
    flex: 1;
  }
}

.btns {
  margin: 30px 0;
  padding-left: 100px;
}
</style>
